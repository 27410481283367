<template>
  <div class="vh-100 w-100 d-flex justify-content-center align-items-center" style="background:#F8F8F8;">
      <div class="custom-card p-4">
          <div class="position-relative d-flex justify-content-center my-4">
              <img :src="Image" alt="">
          </div>
          <div style="font-size:19px;">
              Please check you mail to reset your password.
          </div>
          <div class="d-flex justify-content-start align-items-center my-3">
              <router-link to="/pages/forgotpasswordemail" class="verify-button">
            Resend Link
          </router-link>
          </div>
      </div>
  </div>
</template>

<script>
import { verifyEmailBeta } from "@/utils/api.js";
import Image from '@/assets/images/illustration/pricing-Illustration.svg';

export default {
    data(){
        return {
            Image,
        }
    },
   methods: {
       async useVerifyEmail(){           
           try{let res= await verifyEmailBeta({email: this.$route.query.email});
           console.log(res.data)}
           catch(err){
               console.log(err)
           }
       }
   }
}
</script>

<style lang="scss">
    .custom-card{
        width: 600px;
        min-height: 569px;
        background-color: #fff;
        border-radius: 10px;
        padding: 20px;
        max-width: 95%;
        color:#5E5873;
    }
    .verify-button{
        background-color: #4256EE;
        color: #fff;
        border: none;
        padding: 10px 25px;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
    }
    .resend-email{
        color: #4256EE;
        font-size: 10px;
        cursor: pointer;
    }
</style>